import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { DatePicker } from "@shopify/polaris";
import { Container, Text } from "basis";

import { getDateXDaysAgo } from "../utils/dateFormat";

import { Button, Box, Icon, Modal, Responsive } from ".";
import { COLOR } from "./theme";

const BUTTON_SIZE = "48px";
const ICON_SIZE = "22px";

const StyledInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
  background: ${COLOR.GREY};
  position: relative;
`;

const StyledInput = styled.input`
  outline: none;
  border: none;
  background: transparent;
  padding: 15px;
  width: calc(100% - 30px);
  height: ${BUTTON_SIZE};
`;

const StyledButton = styled.button`
  position: absolute;
  cursor: pointer;
  background: ${COLOR.GREY};
  outline: none;
  border: none;
  min-width: ${BUTTON_SIZE};
  min-height: ${BUTTON_SIZE};
  right: 0;
  top: 0;
`;

const StyledBox = styled(Box)`
  font-family: "Roboto", sans-serif;

  .Polaris-DatePicker__Header svg {
    fill: ${COLOR.LATITUDE_BLUE};
  }

  .Polaris-DatePicker__Day:hover {
    background: ${COLOR.HIGHLIGHT_BLUE};
    color: ${COLOR.WHITE};
  }

  .Polaris-DatePicker__Day--inRange {
    background: ${COLOR.LIGHT_SKY_BLUE};
  }

  .Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hasRange {
    background: ${COLOR.LATITUDE_BLUE};
  }

  .Polaris-DatePicker__Day--lastInRange {
    background: ${COLOR.LATITUDE_BLUE};
    color: ${COLOR.WHITE};
  }
`;

const DEFAULT_RANGE_DAYS = 10;

export const DateRangePicker = ({
  title = "",
  value = "",
  onChange = () => {},
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const now = new Date();

  const [{ month, year }, setDate] = useState({
    month: now.getMonth(),
    year: now.getFullYear(),
  });

  const [selectedDates, setSelectedDates] = useState({
    start: getDateXDaysAgo(DEFAULT_RANGE_DAYS, now),
    end: now,
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const handleRangeChange = () => {
    onChange(selectedDates);
    setIsModalVisible(false);
  };

  const isRangeSelected = String(value)?.length > 0;

  const handleClick = () => setIsModalVisible(true);
  const handleClear = () => onChange({ start: null, end: null });

  return (
    <>
      <Text margin="0 0 3">
        <strong>{title}</strong>
      </Text>

      <StyledInputWrapper>
        <StyledInput
          placeholder={"Select start and end dates"}
          value={value}
          onChange={handleClick}
          onClick={handleClick}
        />

        <StyledButton>
          {!isRangeSelected && (
            <Icon
              type="iconCalendar"
              size={ICON_SIZE}
              color={COLOR.HIGHLIGHT_BLUE}
              hoverColor={COLOR.LATITUDE_BLUE}
              onClick={handleClick}
            />
          )}

          {isRangeSelected && (
            <Icon
              type="iconClose"
              size={ICON_SIZE}
              color={COLOR.HIGHLIGHT_BLUE}
              hoverColor={COLOR.LATITUDE_BLUE}
              onClick={handleClear}
            />
          )}
        </StyledButton>
      </StyledInputWrapper>

      {isModalVisible && (
        <Modal maxWidth="680px">
          <Container margin="2 4">
            <Text textStyle="subtitle2">
              <strong>Enter a date range</strong>
            </Text>

            <StyledBox margin="24px 0" padding="24px 12px">
              <DatePicker
                month={month}
                year={year}
                onChange={setSelectedDates}
                onMonthChange={handleMonthChange}
                selected={selectedDates}
                disableDatesBefore={getDateXDaysAgo(90, now)} // max 90 day history
                disableDatesAfter={getDateXDaysAgo(0, now)} // disable future dates
                multiMonth
                allowRange
              />
            </StyledBox>

            <Responsive>
              <Button showButton fullWidth onClick={handleRangeChange}>
                Apply
              </Button>

              <Button
                showButton
                fullWidth
                variant="secondary"
                onClick={() => setIsModalVisible(false)}
              >
                Cancel
              </Button>
            </Responsive>
          </Container>
        </Modal>
      )}
    </>
  );
};
