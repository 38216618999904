import { PAYMENT_OPTIONS } from "../src/core/constants";
import { MODAL_CLASSES } from "./components/modal/Classes";

export const CURRENCY = {
  AUD: "AUD",
  NZD: "NZD",
};

export const LOGO_URL = {
  [CURRENCY.AUD]:
    "https://assets.latitudefinancial.com/merchant-services/latitude/icon/latitude-logo.svg",
  [CURRENCY.NZD]:
    "https://assets.latitudefinancial.com/merchant-services/latitude/icon/gem-logo.svg",
};

export const CUSTOM_DETAILS = {
  "test-000421316" : {
    merchantName: "David Jones",
    modalTitle: "Apply now",
    modalDesc: "Choose between the <strong>David Jones Premiere credit card</strong> and the <strong>David Jones Prestige credit card</strong>.",
    burnRate: 150,
    companyLogo: 'dj-logo.svg',
    compareLink: "/david-jones-credit-card/compare",
    productHeight: 660,
    products: [
      {
        productName: "David Jones Premiere credit card",
        productLogo: 'dj-premiere.svg',
        learnLink: "/david-jones-credit-card/compare/premiere",
        applyLink: "https://feature-au-ht-main.apply-test.originations-np.lfscnp.com/davidjonespremiere?targetRole=poc-test&merchantID=test-000421316&source=0&stream=Upstream&gemid1=par:davidjones:apply-referral:davidjonespremiere:conversion:checkout-apply-now&utm_medium=par&utm_source=davidjones&utm_campaign=apply-referral&utm_content=davidjonespremiere:conversion:checkout-apply-now",
        annualFee: 99,
        features: [
          `Earn David Jones Points -
            <ul class="${MODAL_CLASSES.productInnerFeatures}">
              <li>2 points per $1 spent at David Jones</li>
              <li>3 points per $1 spent at participating petrol stations & supermarkets</li>
              <li>1 point per $1 spent everywhere else</li>
            </ul>`,
          "Access to David Jones sales, previews, complimentary express delivery, gift wrapping and more.",
          "Special discounts on selected hotel bookings through Expedia, Wotif, & Booking.com.",
          "Complimentary eCommerce protection insurance."
        ]
      },
      {
        productName: "David Jones Prestige credit card",
        productLogo: `dj-prestige.svg`,
        annualFee: 295,
        learnLink: "/david-jones-credit-card/compare/prestige",
        applyLink: "https://feature-au-ht-main.apply-test.originations-np.lfscnp.com/davidjonesprestige?targetRole=poc-test&merchantID=test-000421316&source=0&stream=Upstream&gemid1=par:davidjones:apply-referral:davidjonesprestige:conversion:checkout-apply-now&utm_medium=par&utm_source=davidjones&utm_campaign=apply-referral&utm_content=davidjonesprestige:conversion:checkout-apply-now",
        features: [
          `Earn David Jones Points - 
            <ul class="${MODAL_CLASSES.productInnerFeatures}">
              <li>4 points per $1 spent at David Jones</li>
              <li>3 points per $1 spent at participating petrol stations & supermarkets</li>
              <li>1 point per $1 spent everywhere else</li>
            </ul>`,
          "Exclusive access to David Jones sales, previews and events, complimentary same day delivery, gift wrapping, alterations when shopping instore at David Jones and more.",
          "Complimentary travel insurance.",
          "Access to two complimentary airport lounge passes each year.",
          "Special discounts on selected hotel bookings through Expedia, Wotif, & Booking.com.",
          "Complimentary purchase protection and eCommerce protection insurance.",
          "Additional cardholders are free."
        ]
      }
    ]
  },
  "dev-000421316": {
    merchantName: "David Jones",
    modalTitle: "Apply now",
    modalDesc: "Choose between the <strong>David Jones Premiere credit card</strong> and the <strong>David Jones Prestige credit card</strong>.",
    burnRate: 150,
    companyLogo: 'dj-logo.svg',
    compareLink: "/david-jones-credit-card/compare",
    productHeight: 660,
    products: [
      {
        productName: "David Jones Premiere credit card",
        productLogo: 'dj-premiere.svg',
        learnLink: "/david-jones-credit-card/compare/premiere",
        applyLink: "https://feature-au-ht-main.apply-test.originations-np.lfscnp.com/davidjonespremiere?targetRole=poc-test&merchantID=test-000421316&source=0&stream=Upstream&gemid1=par:davidjones:apply-referral:davidjonespremiere:conversion:checkout-apply-now&utm_medium=par&utm_source=davidjones&utm_campaign=apply-referral&utm_content=davidjonespremiere:conversion:checkout-apply-now",
        annualFee: 99,
        features: [
          `Earn David Jones Points -
            <ul class="${MODAL_CLASSES.productInnerFeatures}">
              <li>2 points per $1 spent at David Jones</li>
              <li>3 points per $1 spent at participating petrol stations & supermarkets</li>
              <li>1 point per $1 spent everywhere else</li>
            </ul>`,
          "Access to David Jones sales, previews, complimentary express delivery, gift wrapping and more.",
          "Special discounts on selected hotel bookings through Expedia, Wotif, & Booking.com.",
          "Complimentary eCommerce protection insurance."
        ]
      },
      {
        productName: "David Jones Prestige credit card",
        productLogo: `dj-prestige.svg`,
        annualFee: 295,
        learnLink: "/david-jones-credit-card/compare/prestige",
        applyLink: "https://feature-au-ht-main.apply-test.originations-np.lfscnp.com/davidjonesprestige?targetRole=poc-test&merchantID=test-000421316&source=0&stream=Upstream&gemid1=par:davidjones:apply-referral:davidjonesprestige:conversion:checkout-apply-now&utm_medium=par&utm_source=davidjones&utm_campaign=apply-referral&utm_content=davidjonesprestige:conversion:checkout-apply-now",
        features: [
          `Earn David Jones Points - 
            <ul class="${MODAL_CLASSES.productInnerFeatures}">
              <li>4 points per $1 spent at David Jones</li>
              <li>3 points per $1 spent at participating petrol stations & supermarkets</li>
              <li>1 point per $1 spent everywhere else</li>
            </ul>`,
          "Exclusive access to David Jones sales, previews and events, complimentary same day delivery, gift wrapping, alterations when shopping instore at David Jones and more.",
          "Complimentary travel insurance.",
          "Access to two complimentary airport lounge passes each year.",
          "Special discounts on selected hotel bookings through Expedia, Wotif, & Booking.com.",
          "Complimentary purchase protection and eCommerce protection insurance.",
          "Additional cardholders are free."
        ]
      }
    ]
  },
  "000421316": {
    merchantName: "David Jones",
    modalTitle: "Apply now",
    modalDesc: "Choose between the <strong>David Jones Premiere credit card</strong> and the <strong>David Jones Prestige credit card</strong>.",
    burnRate: 150,
    companyLogo: 'dj-logo.svg',
    compareLink: "/david-jones-credit-card/compare",
    productHeight: 660,
    products: [
      {
        productName: "David Jones Premiere credit card",
        productLogo: 'dj-premiere.svg',
        learnLink: "/david-jones-credit-card/compare/premiere",
        applyLink: "https://cards.latitudefinancial.com/davidjonespremiere?merchantID=000421316&source=0&stream=Upstream&gemid1=par:davidjones:apply-referral:davidjonespremiere:conversion:checkout-apply-now&utm_medium=par&utm_source=davidjones&utm_campaign=apply-referral&utm_content=davidjonespremiere:conversion:checkout-apply-now",
        annualFee: 99,
        features: [
          `Earn David Jones Points -
            <ul class="${MODAL_CLASSES.productInnerFeatures}">
              <li>2 points per $1 spent at David Jones</li>
              <li>3 points per $1 spent at participating petrol stations & supermarkets</li>
              <li>1 point per $1 spent everywhere else</li>
            </ul>`,
          "Access to David Jones sales, previews, complimentary express delivery, gift wrapping and more.",
          "Special discounts on selected hotel bookings through Expedia, Wotif, & Booking.com.",
          "Complimentary eCommerce protection insurance."
        ]
      },
      {
        productName: "David Jones Prestige credit card",
        productLogo: `dj-prestige.svg`,
        annualFee: 295,
        learnLink: "/david-jones-credit-card/compare/prestige",
        applyLink: "https://cards.latitudefinancial.com/davidjonesprestige?merchantID=000421316&source=0&stream=Upstream&gemid1=par:davidjones:apply-referral:davidjonesprestige:conversion:checkout-apply-now&utm_medium=par&utm_source=davidjones&utm_campaign=apply-referral&utm_content=davidjonesprestige:conversion:checkout-apply-now",
        features: [
          `Earn David Jones Points - 
            <ul class="${MODAL_CLASSES.productInnerFeatures}">
              <li>4 points per $1 spent at David Jones</li>
              <li>3 points per $1 spent at participating petrol stations & supermarkets</li>
              <li>1 point per $1 spent everywhere else</li>
            </ul>`,
          "Exclusive access to David Jones sales, previews and events, complimentary same day delivery, gift wrapping, alterations when shopping instore at David Jones and more.",
          "Complimentary travel insurance.",
          "Access to two complimentary airport lounge passes each year.",
          "Special discounts on selected hotel bookings through Expedia, Wotif, & Booking.com.",
          "Complimentary purchase protection and eCommerce protection insurance.",
          "Additional cardholders are free."
        ]
      }
    ]
  },
  [CURRENCY.AUD] : {
    merchantName: "AU Merchant",
    modalTitle: "AU Generic Title",
    modalDesc: "Template description for AU modal",
    burnRate: 1,
    companyLogo: "https://assets.latitudefinancial.com/merchant-services/latitude/icon/latitude-logo.svg",
    compareLink: "/david-jones-credit-card/compare",
    products: [
      {
        productName: "AU Product 1",
        productLogo: 'dj-premiere.svg',
        learnLink: "/david-jones-credit-card/compare/premiere",
        applyLink: "https://cardapp.latitudefinancial.com/apply/go",
        annualFee: 99,
        features: [
          "feature 1",
          "feature 2",
          "feature 3",
          "feature 4",
          "feature 5"
        ]
      },
      {
        productName: "AU Product 2",
        productLogo: 'dj-prestige.svg',
        annualFee: 295,
        learnLink: "/david-jones-credit-card/compare/prestige",
        applyLink: "https://cardapp.latitudefinancial.com/apply/go",
        features: [
          "feature 1",
          "feature 2",
          "feature 3",
          "feature 4",
          "feature 5",
          "feature 6"
        ]
      }
    ]
  },
  [CURRENCY.NZD] : {
    merchantName: "NZ Merchant",
    modalTitle: "NZ Generic Title",
    modalDesc: "Template description for NZ modal",
    burnRate: 1,
    companyLogo: "https://assets.latitudefinancial.com/merchant-services/latitude/icon/gem-logo.svg",
    compareLink: "/david-jones-credit-card/compare",
    products: [
      {
        productName: "NZ Product 1",
        productLogo: 'dj-premiere.svg',
        learnLink: "/david-jones-credit-card/compare/premiere",
        applyLink: "https://cardapp.latitudefinancial.com/apply/go",
        annualFee: 99,
        features: [
          "feature 1",
          "feature 2",
          "feature 3",
          "feature 4",
          "feature 5"
        ]
      },
      {
        productName: "NZ Product 2",
        productLogo: 'dj-prestige.svg',
        annualFee: 295,
        learnLink: "/david-jones-credit-card/compare/prestige",
        applyLink: "https://cardapp.latitudefinancial.com/apply/go",
        features: [
          "feature 1",
          "feature 2",
          "feature 3",
          "feature 4",
          "feature 5",
          "feature 6"
        ]
      }
    ]
  }
}

export const DOMAIN_MATCHER = /(checkout\.latitudefinancial\.com|develop\.checkout\.test\.merchant-services-np\.lfscnp\.com|dev[0-9]?\.checkout\.dev\.merchant-services-np\.lfscnp\.com|localhost:8100)/gi;

export const LOGO_AU_STACKED =
  "https://assets.latitudefinancial.com/merchant-services/latitude/icon/latitude-logo-stacked.svg";

export const ALLOWED_PAYMENT_OPTIONS = [
  PAYMENT_OPTIONS.EQUAL,
  PAYMENT_OPTIONS.MINIMUM,
];

export const INVERSED_LAYOUTS = [
  'inversed',
  'single-line-inversed',
];

export const ACCCOUNT_FEES = {
  [CURRENCY.AUD]: "Excludes $99 annual fee.",
  [CURRENCY.NZD]: "Excludes $52 annual account fee.",
};

export const LAYOUT_TYPE_CUSTOM = "custom";

export const ALLOWED_PROMOTION_LENGTH = [6, 12, 18, 24, 30, 36, 48, 50, 60];

export const SCRIPT_MATCHER = /(checkout.latitudefinancial.com|merchant-services-np.lfscnp.com)/gi;

export const EVENT_HANDLERS = {
  LEARN_MORE_CLICK: "LatitudeCheckout.eventHandlers.learnMoreClickEventHandler",
};

export const MAX_PRICE_MAP = {
  12: {
    priceThreshold: 1200,
  },
  18: {
    priceThreshold: 1800,
  },
  24: {
    priceThreshold: 2400,
  },
};

export const MIN_PRODUCT_PRICE = 250;

// re-exported constants
export { PAYMENT_OPTIONS };

export const LOCATION = {
  WIDGET: "widget",
  CHECKOUT: "checkout",
};


export const WIDGET_DEFAULT_MINIMUM_AMOUNT_CENTS = 25000;